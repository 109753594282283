import { Dispatch, MouseEvent, SetStateAction, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { SongMoreMenu, Tooltip } from 'components/UI';
import { RowNodeExt } from 'components/UI/DataGrid';
import { ActionButtonsTypes } from 'components/UI/SongMoreMenu/data';

import { useMemoSelector } from 'hooks';
import Api, { downloadAudio } from 'services/Api';
import {
  getSongsModuleMyFiltersData,
  getUserDefaultTeam,
  getUserPermissions,
  setError,
  setHoldRequest,
  setMainConfirmPopup,
  setMainNotification,
  setPitchesModule,
  setRecentEditedSongs,
  setShareConfig,
} from 'store';
import { PitchContentType, ShareItemTypes } from 'store/reducers/general/types';
import {
  ARCHIVE_ERROR_CONFIG,
  DEFAULT_ERROR_CONFIG,
  DEFAULT_MAIN_CONFIRM_POPUP,
  getSongVersion,
  IActionButtons,
  songsActionButtons,
} from 'utils';

import styles from './Songs.module.scss';

import { ISongData, SongsTableFilterTypes } from './data';
import { SongStatusTypes, SongsWorkTypes } from 'types';

interface IActionsCellProps {
  node: RowNodeExt;
  data?: ISongData;
  setReloadHash?: Dispatch<SetStateAction<string | undefined>>;
}

const ActionsCell = ({ node, data, setReloadHash }: IActionsCellProps) => {
  const dispatch = useDispatch();

  const { permissions, myFilter, userDefaultTeam } = useMemoSelector((state) => ({
    permissions: getUserPermissions(state),
    myFilter: getSongsModuleMyFiltersData(state),
    userDefaultTeam: getUserDefaultTeam(state),
  }));

  const [isOpenActionsDropdown, setIsOpenActionsDropdown] = useState(false);

  const isArchived = useMemo(() => myFilter.filterType === SongsTableFilterTypes.archived, [myFilter.filterType]);
  const isPWSong = useMemo(() => data?.type === SongsWorkTypes.preliminaryWork, [data]);
  const isWithAudio = useMemo(() => !!data?.mediaPath, [data]);

  const actionsCellRef = useRef<HTMLDivElement | null>(null);

  const closeMainConfirmPopup = () => dispatch(setMainConfirmPopup(DEFAULT_MAIN_CONFIRM_POPUP));
  const setNotification = (text: string) => dispatch(setMainNotification(text));
  const reloadTable = () => setReloadHash && setReloadHash(new Date().toISOString());

  const closeConfirmPopupSetNotificationReloadTable = (notificationText: string, update?: Partial<ISongData>) => {
    update &&
      dispatch(setRecentEditedSongs({ songs: [{ ids: { songId: data?.id, mediaId: data?.mediaId || '' }, update }] }));
    closeMainConfirmPopup();
    setNotification(notificationText);
    reloadTable();
  };

  const handleDeleteSong = async () => {
    if (!data) return;

    const res = await Api.deleteSongs([data.id], { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    if (!res) return;

    closeConfirmPopupSetNotificationReloadTable('Song Deleted', { status: SongStatusTypes.DELETED });
  };

  const handleRestoreSong = async () => {
    if (!data) return;

    const res = await Api.restoreSongs([data.id], { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    if (!res) return;

    closeConfirmPopupSetNotificationReloadTable('Song Restored', { status: SongStatusTypes.ACTIVE });
  };

  const handleArchiveSong = async () => {
    if (!data) return;

    if (userDefaultTeam?.id !== data.teamId) {
      return dispatch(setError(ARCHIVE_ERROR_CONFIG));
    }

    const res = await Api.archiveSongs([data.id], { errorPopupConfig: DEFAULT_ERROR_CONFIG });

    if (!res) return;

    closeConfirmPopupSetNotificationReloadTable('Song Archived', { status: SongStatusTypes.ARCHIVED });
  };

  const onClickAction = async (e: MouseEvent, type: ActionButtonsTypes) => {
    e.stopPropagation();

    if (type !== ActionButtonsTypes.actions) {
      setIsOpenActionsDropdown(false);
    }

    switch (type) {
      case ActionButtonsTypes.holdRequest:
        dispatch(setHoldRequest({ form: { isOpen: true, workId: data?.id || '' } }));
        break;
      case ActionButtonsTypes.share:
        data?.mediaId && dispatch(setShareConfig({ isOpen: true, songId: data?.mediaId, type: ShareItemTypes.song }));
        break;
      case ActionButtonsTypes.download:
        data?.mediaPath &&
          (await downloadAudio(data?.mediaPath || '', `${data?.title} (${getSongVersion(data.version)})`));
        break;
      case ActionButtonsTypes.pitch:
        data &&
          dispatch(
            setPitchesModule({
              systemPitchForm: {
                songs: [
                  {
                    id: data.id,
                    mediaId: data.mediaId,
                    path: data.mediaPath,
                    title: data.title,
                    version: data.version,
                    writers: data.writers,
                  },
                ],
              },
              pitchContentType: PitchContentType.multipleSongPitch,
            })
          );
        break;
      case ActionButtonsTypes.actions:
        setIsOpenActionsDropdown(!isOpenActionsDropdown);
        break;
      case ActionButtonsTypes.expand:
        node.setDetailsRow(!node.expanded, 107, true);
        break;
      case ActionButtonsTypes.archive:
        dispatch(
          setMainConfirmPopup({
            isOpen: true,
            questionText: 'Archive Selected Song?',
            mainText:
              'This will remove it from songs, playlists and pitches. If you restore, it will only restore the song to the songs list.',
            btnDoneText: 'Archive',
            onClickSubmit: handleArchiveSong,
          })
        );
        break;
      case ActionButtonsTypes.delete:
        dispatch(
          setMainConfirmPopup({
            isOpen: true,
            isRedDoneButton: true,
            questionText: 'Delete Selected Song?',
            mainText: 'This will permanently delete the song from the system.',
            btnDoneText: 'Permanently Delete',
            onClickSubmit: handleDeleteSong,
          })
        );
        break;
      case ActionButtonsTypes.restore:
        dispatch(
          setMainConfirmPopup({
            isOpen: true,
            questionText: 'Restore Selected Song?',
            mainText:
              'This will restore the song to the songs list and allow the song to be pitched and added to playlists. This will not restore the song to previously added playlists and created pitches.',
            btnDoneText: 'Restore',
            onClickSubmit: handleRestoreSong,
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.actionsCellContainer} ref={actionsCellRef}>
      <div className={classNames(styles.actionsCellMain, styles.actionsCellBase)}>
        {(permissions.songsActionButtons as IActionButtons[]).map(({ id, Icon, type, title }) => {
          const isRestoreAndDelete = [ActionButtonsTypes.restore, ActionButtonsTypes.delete].includes(type);

          if (!isPWSong && type === ActionButtonsTypes.archive) {
            return null;
          }

          if ((!isArchived && isRestoreAndDelete) || (isArchived && !isRestoreAndDelete)) {
            return null;
          }

          if (
            [ActionButtonsTypes.pitch, ActionButtonsTypes.download, ActionButtonsTypes.share].includes(type) &&
            type !== ActionButtonsTypes.holdRequest &&
            !data?.mediaId
          ) {
            return <Icon key={id} className={styles.disabledActionBtn} onClick={(e) => onClickAction(e, type)} />;
          }

          return (
            <Tooltip key={id} text={title}>
              <Icon
                onClick={(e) => {
                  return onClickAction(e, type);
                }}
              />
            </Tooltip>
          );
        })}
      </div>
      <div className={classNames(styles.actionsCellMain, styles.actionsCellMainTablet, styles.actionsCellMainMobile)}>
        {songsActionButtons.slice(7, 9).map(({ id, Icon, type, hideInMobile }) => (
          <Icon
            className={classNames({ [styles.hideInMobile]: hideInMobile })}
            key={id}
            onClick={(e) => onClickAction(e, type)}
          />
        ))}
        <SongMoreMenu
          open={isOpenActionsDropdown}
          anchorEl={actionsCellRef.current}
          onClose={() => setIsOpenActionsDropdown(false)}
          onClick={onClickAction}
          isArchived={isArchived}
          isForSongsModule
          isPWSong={isPWSong}
          isWithAudio={isWithAudio}
        />
      </div>
    </div>
  );
};

export default ActionsCell;

import { batch, useDispatch } from 'react-redux';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import { ISongData } from 'components/Songs/data';

import { usePlayIconHeaderConfig } from 'hooks';
import { setActiveTrack, setCurrentTracks } from 'store';
import { immediatelyPlayAudio } from 'utils';

import { ReactComponent as PlayCircleIcon } from 'assets/play-circle.svg';
import styles from './Songs.module.scss';

import { ICurrentTrack, IHoldRequestData } from 'types';

const PlayIconHeaderCell = ({ api }: { api: GridApi }) => {
  const dispatch = useDispatch();

  const { dataGridItems, isPlayDisabled } = usePlayIconHeaderConfig<ISongData>({ api });

  const onClickPlay = () => {
    const rows = dataGridItems.reduce((total, item) => {
      return [
        ...total,
        ...(item.mediaId
          ? [
              {
                mediaId: item.mediaId,
                id: (item as unknown as IHoldRequestData).songId || item.id || '',
                title: item.title || '',
                path: item.mediaPath || '',
                writers: item?.writers,
                version: item.version || '',
              },
            ]
          : []),
      ];
    }, [] as ICurrentTrack[]);

    batch(() => {
      dispatch(setCurrentTracks(rows));
      dispatch(setActiveTrack({ index: 0 }));
    });

    immediatelyPlayAudio();
  };

  return <PlayCircleIcon className={classNames({ [styles.disabledPlayIcon]: isPlayDisabled })} onClick={onClickPlay} />;
};

export default PlayIconHeaderCell;

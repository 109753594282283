import { AssetStatus } from 'services/Api';

export const getProcessingPercentage = (assetStatus: AssetStatus | undefined | null): number | undefined => {
  return assetStatus
    ? assetStatus === AssetStatus.Uploaded
      ? 25
      : assetStatus === AssetStatus.Processing
      ? 50
      : undefined
    : 0;
};

import React, { useState } from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { History } from 'history';

import { Tab } from 'components/Reusable';
import { TabProps } from 'components/Reusable/Tab';

import { useDidUpdate } from 'hooks';

import styles from './Tabs.module.scss';

export interface TabsProps extends React.PropsWithChildren<unknown> {
  className?: string;
  contentClassName?: string;
  disableHistory?: boolean;
  historyKey?: string;
  onChange?: (selectedTab: string) => void;
  selectedTab?: string;
}

const getItemByValue = (items: TabProps[], value: string | undefined): TabProps | undefined => {
  for (const item of items) {
    if (item.value === value) {
      return item;
    }
  }
  return undefined;
};

const getItems = (props: TabsProps): TabProps[] => {
  const items: TabProps[] = [];
  React.Children.forEach(React.Children.toArray(props.children), (child) => {
    if (React.isValidElement(child) && (child.type as React.ComponentType)?.name === Tab.name) {
      items.push(child.props);
    }
  });
  return items;
};

const updateHistory = (selectedTab: string | undefined, history: History<unknown>, historyKey?: string): void => {
  if (selectedTab) {
    const hash = typeof window === 'undefined' ? '#' : window.location.hash;
    const urlSearchParams = new URLSearchParams(hash.replace('#', '?'));
    urlSearchParams.set(historyKey || 'tab', selectedTab);
    history.push(`#${urlSearchParams.toString().replace('?', '')}`);
  }
};

const getInitialTab = ({ disableHistory, historyKey, selectedTab }: TabsProps): string | undefined => {
  if (disableHistory) return selectedTab;
  const hash = typeof window === 'undefined' ? '#' : window.location.hash;
  const urlSearchParams = new URLSearchParams(hash.replace('#', '?'));
  return urlSearchParams.get(historyKey || 'tab') || undefined;
};

const Tabs = (props: TabsProps): JSX.Element => {
  const items = getItems(props);
  const [selectedTab, setSelectedTab] = useState<string | undefined>(() => getInitialTab(props));
  const selectedItem = getItemByValue(items, selectedTab) || items[0];
  const history = useHistory();

  useDidUpdate(() => {
    !props.disableHistory && updateHistory(selectedTab, history, props.historyKey);
  }, [history, selectedTab, props.historyKey]);

  return (
    <div className={props.className}>
      <div className="flex">
        {items.map((item) => (
          <button
            className={classNames(styles.button, {
              [styles.selectedButton]: selectedItem === item,
              [styles.hideInMobile]: item.hideInMobile,
            })}
            key={item.value}
            onClick={(e) => {
              e.preventDefault();
              setSelectedTab(item.value);
              props.onChange && props.onChange(item.value);
            }}
          >
            {item.label}
          </button>
        ))}
      </div>
      <div className={props.contentClassName} key={selectedItem?.value}>
        {selectedItem?.children || null}
      </div>
    </div>
  );
};

export default Tabs;

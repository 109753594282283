import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { AddToPlaylistPopup } from 'components/Popups';

import { useMemoSelector } from 'hooks';
import { getUserPermissions, setIsExpandedPlaybar, setIsOpenMobileMockup, setPitchesModule } from 'store';
import { PitchContentType } from 'store/reducers/general/types';

import { ReactComponent as ArrowDownIcon } from 'assets/arrow.svg';
import { ReactComponent as MoreIcon } from 'assets/more.svg';
import { ReactComponent as PitchIcon } from 'assets/pitch.svg';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { ReactComponent as VolumeIcon } from 'assets/volume.svg';
import styles from './MusicPlayer.module.scss';

import { PlayerMorePopup, PlayerVolumePopup, TrackInfo } from '.';
import { IAddToPlaylist, IPlayerActions, PlayerPopups } from './IMusicPlayer';
import { SongStatusTypes } from 'types';

interface IPlayerActionsProps extends Omit<IPlayerActions, 'actionMarker' | 'disableIcon'> {
  aRef: MutableRefObject<HTMLAudioElement | undefined>;
  track: TrackInfo;
  setAddToPlaylist: Dispatch<SetStateAction<IAddToPlaylist>>;
  setOpenFavoriteSnackbar: Dispatch<SetStateAction<boolean>>;
  isExternal?: boolean;
}

const PlayerActions = ({
  aRef,
  checkIsPopup,
  changeOpenPopup,
  popupContainerRef,
  track,
  setAddToPlaylist,
  setOpenFavoriteSnackbar,
  isExternal,
}: IPlayerActionsProps) => {
  const dispatch = useDispatch();

  const permissions = useMemoSelector(getUserPermissions);

  const onClickMoreIcon = () => {
    dispatch(setIsOpenMobileMockup(true));
    changeOpenPopup(PlayerPopups.more);
  };

  return (
    <div
      className={classNames(styles.actionsContainer, {
        [styles.hidden]: !track,
      })}
    >
      {!isExternal && (
        <>
          {track?.songId && permissions.playbarPitch && (
            <PitchIcon
              className={classNames(styles.desktopIcon, {
                [styles.disabledIcon]:
                  !track || !track?.version || [SongStatusTypes.ARCHIVED, ''].includes(track?.status),
              })}
              onClick={() =>
                track.songId &&
                dispatch(
                  setPitchesModule({
                    systemPitchForm: {
                      songs: [
                        {
                          id: track.songId,
                          mediaId: track.id,
                          path: track.path,
                          title: track.title,
                          version: track.version,
                          writers: track.writers,
                        },
                      ],
                    },
                    pitchContentType: PitchContentType.multipleSongPitch,
                  })
                )
              }
            />
          )}
          <AddToPlaylistPopup
            medias={track ? [{ id: track.id, title: track.title }] : []}
            setAddToPlaylist={setAddToPlaylist}
            setOpenFavoriteSnackbar={setOpenFavoriteSnackbar}
          >
            <PlusIcon
              className={classNames(styles.desktopIcon, {
                [styles.activeIcon]: checkIsPopup(PlayerPopups.plus),
                [styles.disabledIcon]:
                  !track || !track?.version || [SongStatusTypes.ARCHIVED, ''].includes(track?.status),
              })}
              onClick={() => changeOpenPopup(PlayerPopups.plus)}
            />
          </AddToPlaylistPopup>
        </>
      )}
      <VolumeIcon
        className={classNames(styles.desktopIcon, { [styles.activeIcon]: checkIsPopup(PlayerPopups.volume) })}
        onClick={() => changeOpenPopup(PlayerPopups.volume)}
      />
      <PlayerVolumePopup aRef={aRef} isShow={checkIsPopup(PlayerPopups.volume)} popupContainerRef={popupContainerRef} />
      {!isExternal && (
        <>
          <MoreIcon
            className={classNames(styles.moreIcon, {
              [styles.activeIcon]: checkIsPopup(PlayerPopups.more),
              [styles.disabledIcon]:
                !track || !track?.version || [SongStatusTypes.ARCHIVED, ''].includes(track?.status),
            })}
            onClick={onClickMoreIcon}
          />
          <PlayerMorePopup
            track={track}
            changeOpenPopup={changeOpenPopup}
            isShow={checkIsPopup(PlayerPopups.more)}
            popupContainerRef={popupContainerRef}
          />
        </>
      )}
      <ArrowDownIcon
        className={classNames(styles.arrowDownIcon)}
        onClick={() => dispatch(setIsExpandedPlaybar(false))}
      />
    </div>
  );
};

export default PlayerActions;

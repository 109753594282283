import { useCallback, useEffect, useMemo } from 'react';
import { batch, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useMemoSelector } from 'hooks';
import {
  getActiveTrackIndex,
  getCurrentTracks,
  getPlaybarPlayingState,
  getUserPermissions,
  setActiveTrack,
  setCurrentTracks,
  setPlaybarPlayingState,
} from 'store';
import { PlayingStateTypes } from 'store/reducers/general/types';
import { shuffleArray } from 'utils';

import { ReactComponent as ShuffleIcon } from 'assets/shuffle.svg';
import styles from './MusicPlayer.module.scss';
import { ReactComponent as NextIcon } from './icons/next.svg';
import { ReactComponent as PauseIcon } from './icons/pause.svg';
import { ReactComponent as PlayIcon } from './icons/play.svg';
import { ReactComponent as PrevIcon } from './icons/prev.svg';

import { FavoriteContainer, IPlayControlsProps, MarkerControl } from '.';
import { IFavoriteContainerProps, IPlayerActions } from './IMusicPlayer';
import { SongStatusTypes } from 'types';

interface IPlayControls
  extends IPlayControlsProps,
    Omit<IPlayerActions, 'disableIcon'>,
    Omit<IFavoriteContainerProps, 'disableIcon'> {}

const PlayControls = ({
  tracks,
  aRef,
  track,
  onProgress,
  onTrackChange,
  setTrack,
  setDuration,
  setProgress,
  setTime,
  actionMarker,
  checkIsPopup,
  changeOpenPopup,
  popupContainerRef,
  favoriteConfig,
  toggleFavorite,
  isExternal,
  updateTracks,
}: IPlayControls) => {
  const dispatch = useDispatch();

  const { currentTracks, activeTrackIndex, permissions, playbarPlayingState } = useMemoSelector((state) => ({
    currentTracks: getCurrentTracks(state),
    activeTrackIndex: getActiveTrackIndex(state),
    permissions: getUserPermissions(state),
    playbarPlayingState: getPlaybarPlayingState(state),
  }));

  const trackInfo = useMemo(
    () => ({
      id: tracks[0]?.id,
      peeksLength: tracks[0]?.peeks?.length,
      tracksLength: tracks.length,
    }),
    [tracks]
  );

  useEffect(() => {
    const audio = aRef.current as HTMLAudioElement;

    // Getting audio duration on can play event
    audio.oncanplay = (e) => {
      const { duration, currentTime } = e.target as HTMLAudioElement;
      if (currentTime > 0) return;
      setDuration(duration);
    };

    // Get progress status on time update event (fired on playback and rewind)
    audio.ontimeupdate = (e) => {
      const { duration, currentTime } = e.target as HTMLAudioElement;
      setDuration(duration);
      setTime(currentTime);
      setProgress((currentTime / (duration || 1)) * 100);
      onProgress && onProgress((currentTime / duration) * 100);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Track navigation and updates
  useEffect(() => {
    const currentTrack = tracks[0];

    if (!currentTrack) return;

    setTrack(currentTrack);
    dispatch(
      setActiveTrack({
        songId: currentTrack.songId,
        mediaId: currentTrack.id,
        isHaveMarkers: !!currentTrack.markers?.length,
      })
    );
    onTrackChange && onTrackChange(currentTrack.path);
  }, [dispatch, onTrackChange, setTrack, tracks]);

  // Play button handler
  const playHandler = useCallback(() => {
    const audio = aRef.current as HTMLAudioElement;
    setTimeout(() => {
      audio
        .play()
        .then((res) => {
          dispatch(setPlaybarPlayingState(PlayingStateTypes.playing));
          return res;
        })
        .catch(() => dispatch(setPlaybarPlayingState(PlayingStateTypes.error)));
    }, 100);
  }, [aRef, dispatch]);

  // Pause button handler
  const pauseHandler = useCallback(() => {
    const audio = aRef.current as HTMLAudioElement;
    dispatch(setPlaybarPlayingState(PlayingStateTypes.paused));
    audio.pause();
  }, [aRef, dispatch]);

  // Previous track navigation handler
  const prevTrackHandler = useCallback(() => {
    const audio = aRef.current as HTMLAudioElement;

    const prevIndex = activeTrackIndex !== 0 ? activeTrackIndex - 1 : currentTracks.length - 1;

    setProgress(0);
    setDuration(0);
    if (audio.currentTime > 3) {
      audio.currentTime = 0;
      return;
    }
    updateTracks(currentTracks[prevIndex]);
    dispatch(setActiveTrack({ index: prevIndex }));
  }, [aRef, activeTrackIndex, currentTracks, updateTracks, setProgress, setDuration, dispatch]);

  // Next track navigation handler
  const nextTrackHandler = useCallback(async () => {
    const nextIndex = activeTrackIndex < currentTracks.length - 1 ? activeTrackIndex + 1 : 0;
    setTime(0);
    await updateTracks(currentTracks[nextIndex]);
    setProgress(0);
    setDuration(0);
    dispatch(setActiveTrack({ index: nextIndex }));
  }, [setTime, currentTracks, activeTrackIndex, setDuration, setProgress, updateTracks, dispatch]);

  const onClickShuffle = () => {
    if (currentTracks.length <= 1) {
      return;
    }

    const shuffledArray = shuffleArray(currentTracks);
    const newIndexOfCurrentSong = shuffledArray.findIndex((el) => el.mediaId === track.id);

    batch(() => {
      dispatch(setCurrentTracks(shuffledArray));
      dispatch(setActiveTrack({ index: newIndexOfCurrentSong }));
    });
  };

  useEffect(() => {
    if (!trackInfo.tracksLength || !trackInfo.peeksLength) return;
    playHandler();
  }, [trackInfo.tracksLength, trackInfo.id, trackInfo.peeksLength, playHandler]);

  // Handle audio on ended event to switch to a next track automatically
  useEffect(() => {
    const audio = aRef.current as HTMLAudioElement;

    audio.onended = async () => {
      setTime(0);
      setProgress(0);
      setDuration(0);

      if (currentTracks.length === 1) {
        playHandler();
        return;
      }

      const nextIndex = activeTrackIndex < currentTracks.length - 1 ? activeTrackIndex + 1 : 0;
      await updateTracks(currentTracks[nextIndex]);

      dispatch(setActiveTrack({ index: nextIndex }));
    };
  }, [
    aRef,
    currentTracks.length,
    activeTrackIndex,
    setDuration,
    setProgress,
    setTime,
    dispatch,
    currentTracks,
    updateTracks,
    playHandler,
  ]);

  return (
    <div className={styles.playControlsContainer}>
      {!isExternal && (
        <button
          className={classNames(styles.controlBtn, styles.expandedBtn, {
            [styles.disabledIcon]: !track || currentTracks.length <= 1,
          })}
          onClick={onClickShuffle}
        >
          <ShuffleIcon />
        </button>
      )}
      <button
        onClick={prevTrackHandler}
        className={classNames(styles.controlBtn, styles.expandedBtn, {
          [styles.disabledIcon]: currentTracks.length <= 1,
        })}
      >
        <PrevIcon className={styles.nextPrevIcon} />
      </button>
      {playbarPlayingState !== PlayingStateTypes.playing ? (
        <button
          onClick={playHandler}
          className={classNames(styles.controlBtn, { [styles.disabledIcon]: !track || !track?.peeks?.length })}
          disabled={!track}
        >
          <PlayIcon
            className={classNames(styles.playPauseIcon, {
              [styles.disabledIcon]: !track,
            })}
          />
        </button>
      ) : (
        <button
          onClick={pauseHandler}
          className={classNames(styles.controlBtn, {
            [styles.disabledIcon]: !track || !track?.peeks?.length,
          })}
          disabled={!track}
        >
          <PauseIcon className={styles.playPauseIcon} />
        </button>
      )}
      <button
        onClick={nextTrackHandler}
        className={classNames(styles.controlBtn, styles.expandedBtn, {
          [styles.disabledIcon]: currentTracks.length <= 1,
        })}
      >
        <NextIcon className={styles.nextPrevIcon} />
      </button>
      {!isExternal && permissions.playbarAddMarker && (
        <MarkerControl
          checkIsPopup={checkIsPopup}
          changeOpenPopup={changeOpenPopup}
          actionMarker={actionMarker}
          popupContainerRef={popupContainerRef}
          disableIcon={!track || !track?.version}
        />
      )}
      {!isExternal && (
        <FavoriteContainer
          favoriteConfig={favoriteConfig}
          toggleFavorite={toggleFavorite}
          disableIcon={!track || !track?.version || [SongStatusTypes.ARCHIVED, ''].includes(track?.status)}
        />
      )}
    </div>
  );
};

export default PlayControls;

import { batch, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import { usePlayIconHeaderConfig } from 'hooks';
import { setActiveTrack, setCurrentTracks } from 'store';
import { immediatelyPlayAudio } from 'utils';

import { ReactComponent as PlayCircleIcon } from 'assets/play-circle.svg';
import styles from './SongDetails.module.scss';

import { ICurrentTrack, ISongMedias } from 'types';

const PlayIconHeaderCell = ({ api }: { api: GridApi }) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { dataGridItems, isPlayDisabled } = usePlayIconHeaderConfig<ISongMedias>({ api, key: 'id' });

  return (
    <PlayCircleIcon
      className={classNames({ [styles.disabledPlayIcon]: isPlayDisabled })}
      onClick={() => {
        const rows = dataGridItems.reduce((total, item) => {
          return [
            ...total,
            {
              mediaId: item.id,
              id,
              title: item.songTitle || '',
              waveForm: JSON.parse(item.waveform || '[]'),
              writers: item.writers?.map((item) => item.name || '') || [],
              path: item.path,
              version: typeof item.version === 'object' ? item.version?.shortTitle : item.version,
            },
          ];
        }, [] as ICurrentTrack[]);

        batch(() => {
          dispatch(setCurrentTracks(rows));
          dispatch(setActiveTrack({ index: 0 }));
        });

        immediatelyPlayAudio();
      }}
    />
  );
};

export default PlayIconHeaderCell;

import { useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { SongsSearchTypes } from 'components/Popups/DesktopSearch/data';
import { Tab, Tabs } from 'components/Reusable';
import SongsUpload from 'components/SongsUpload';

import { useMemoSelector, useMount } from 'hooks';
import {
  getSongsModuleMyFiltersData,
  getUserId,
  getUserPermissions,
  getUserTeamIds,
  setSearchData,
  setSongsModule,
} from 'store';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './Songs.module.scss';

import { SongsDropzone, SongsHeader, SongsTable } from './';
import { SongsTableFilterTypes } from './data';

const Songs = (): JSX.Element => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [draftExpanded, setDraftExpanded] = useState(false);
  const [reloadHash, setReloadHash] = useState<string | undefined>();

  const { userId, userTeamIds, myFilterData, permissions } = useMemoSelector((state) => ({
    userId: getUserId(state),
    userTeamIds: getUserTeamIds(state),
    myFilterData: getSongsModuleMyFiltersData(state),
    permissions: getUserPermissions(state),
  }));

  const onClickTab = (tab: SongsTableFilterTypes) => {
    const myFilter = {
      filterType: tab,
      ...(SongsTableFilterTypes.myUploads === tab && { userId }),
      ...([SongsTableFilterTypes.teamUploads, SongsTableFilterTypes.all].includes(tab) && { teamIds: userTeamIds }),
    };

    batch(() => {
      dispatch(setSongsModule({ myFilter }));
      dispatch(setSearchData({ searchType: SongsSearchTypes.songSearch }));
    });
  };

  useMount(() => {
    onClickTab(
      permissions.viewSongsTabs
        ? ((location.hash.toString().replace('#tab=', '') || SongsTableFilterTypes.all) as SongsTableFilterTypes)
        : SongsTableFilterTypes.all
    );
  });

  return (
    <div className={classNames(styles.songs, globalStyles.wrapper)}>
      <SongsHeader />
      {permissions.viewSongsTabs && (
        <Tabs
          className={styles.songTabs}
          contentClassName={styles.songTabsContent}
          onChange={(tab) => onClickTab(tab as SongsTableFilterTypes)}
          selectedTab={myFilterData.filterType}
        >
          <Tab label="ALL" value={SongsTableFilterTypes.all}>
            <SongsTable isSongsPage reloadHash={reloadHash} setReloadHash={setReloadHash} />
          </Tab>
          <Tab label="MY UPLOADS" value={SongsTableFilterTypes.myUploads}>
            <SongsDropzone onDrop={() => setDraftExpanded(true)} />
            <span className={styles.hideInMobile}>
              <h2 className={styles.heading}>Draft Uploads</h2>
              <SongsUpload
                expanded={draftExpanded}
                onExpand={setDraftExpanded}
                // Note: remove after latency issue fix from BE
                onSave={() => setTimeout(() => setReloadHash(new Date().toISOString()), 1000)}
              />
            </span>
            <SongsTable isSongsPage reloadHash={reloadHash} setReloadHash={setReloadHash} title="Saved Uploads" />
          </Tab>
          <Tab label="TEAM UPLOADS" value={SongsTableFilterTypes.teamUploads}>
            <SongsTable isSongsPage reloadHash={reloadHash} setReloadHash={setReloadHash} />
          </Tab>
          <Tab hideInMobile label="ARCHIVED" value={SongsTableFilterTypes.archived}>
            <SongsTable isSongsPage reloadHash={reloadHash} setReloadHash={setReloadHash} />
          </Tab>
        </Tabs>
      )}
      {permissions.viewAllSongs && <SongsTable isSongsPage reloadHash={reloadHash} setReloadHash={setReloadHash} />}
    </div>
  );
};

export default Songs;

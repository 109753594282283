import { memo, MutableRefObject } from 'react';

import { PLAYBAR_AUDIO_ELEMENT_ID } from 'utils';

interface IPlayerAudioProps {
  aRef: MutableRefObject<HTMLAudioElement | undefined>;
  trackPath?: string;
}

const PlayerAudio = ({ aRef, trackPath }: IPlayerAudioProps) => {
  // eslint-disable-next-line
  return <audio id={PLAYBAR_AUDIO_ELEMENT_ID} src={trackPath} ref={aRef as MutableRefObject<HTMLAudioElement>} />;
};

export default memo(PlayerAudio);

import { Dispatch, SetStateAction } from 'react';

import { ISongData } from 'components/Songs/data';

import { IMarkerBE, SongStatusTypes } from 'types';

export enum SidebarStateTypes {
  main = 'main',
  create = 'create',
  edit = 'edit',
  view = 'view',
  favorite = 'favorite',
  createFeatured = 'createFeatured',
  editFeatured = 'editFeatured',
}

export const SidebarTitles = {
  [SidebarStateTypes.view]: '',
  [SidebarStateTypes.edit]: 'Edit Playlist',
  [SidebarStateTypes.main]: 'Playlists',
  [SidebarStateTypes.create]: 'Create Playlist',
  [SidebarStateTypes.favorite]: 'My Favorite Songs',
  [SidebarStateTypes.createFeatured]: 'Create Featured Playlist',
  [SidebarStateTypes.editFeatured]: 'Edit Featured Playlist',
};

export interface IFilterByPlaylist {
  id: number;
  title: string;
  code: string;
}

export const filterByPlaylist: IFilterByPlaylist[] = [
  {
    id: 1,
    title: 'All',
    code: 'all',
  },
  {
    id: 2,
    title: 'My playlists',
    code: 'created_by_me',
  },
  {
    id: 3,
    title: 'Team playlists',
    code: 'created_by_my_team',
  },
  {
    id: 4,
    title: 'Shared by me',
    code: 'shared_by_me',
  },
  {
    id: 5,
    title: 'Shared with me',
    code: 'shared_with_me',
  },
  {
    id: 6,
    title: 'Archived',
    code: 'archived',
  },
];

export interface ISortByPlaylist extends IFilterByPlaylist {
  type: string;
}

export const sortByPlaylist: ISortByPlaylist[] = [
  {
    id: 1,
    title: 'All',
    code: '',
    type: '',
  },
  {
    id: 2,
    title: 'Last updated',
    code: 'updated_on',
    type: 'desc',
  },
  {
    id: 3,
    title: 'First updated',
    code: 'updated_on',
    type: 'asc',
  },
  {
    id: 4,
    title: 'A to Z',
    code: 'title',
    type: 'asc',
  },
  {
    id: 5,
    title: 'Z to A',
    code: 'title',
    type: 'desc',
  },
];

export interface IPlaylist {
  id: string;
  title: string;
  keywords: string[];
  mediaIds: string[];
  mediaCount: number;
  createdBy: string;
  teamId: string;
  createdOnTime: string;
  createdByName: string;
  updatedBy: string;
  updatedByName: string;
  updatedOnTime: string;
  default: boolean;
  featured?: boolean;
}

export interface IPlaylistQuickSearchResult {
  id: string;
  name: string;
}

export interface IFavoritesData {
  id: string;
  updatedByName: string;
  updatedOnTime: string;
  createdByName: string;
  createdOnTime: string;
}

export interface IPlaylistMedia {
  id: string;
  songId: string;
  mediaPath: string;
  title: string;
  version: string | null;
  order: number;
  createdOn: string;
  createdBy: string;
  createdByName: string;
  creators: string[];
  workStatus: SongStatusTypes;
  labelView?: boolean;
  markers?: IMarkerBE[];
  waveForm: number[];
  workType: string;
}

export interface IPlaylistDetails {
  id: string;
  title: string;
  isDefault: boolean;
  keywords: string[];
  medias: IPlaylistMedia[];
  createdBy: string;
  teamId: string;
  createdOnTime: string;
  createdByName: string;
  updatedBy: string;
  updatedOnTime: string;
  updatedByName: string;
  status: null | string;
  // Featured props
  coverPhoto?: string;
  expireOn?: string;
  releaseOn?: string;
  isFeatured?: boolean;
}

export type IDroppedSongType = ISongData | IPlaylistMedia;

export interface IDroppedSongs {
  droppedSongs: Array<IDroppedSongType>;
  setDroppedSongs: Dispatch<SetStateAction<Array<IDroppedSongType>>>;
}

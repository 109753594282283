import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Button, { ButtonVariant } from 'components/Reusable/Button';
import { ActionButtonsTypes } from 'components/UI/SongMoreMenu/data';

import { useMemoSelector } from 'hooks';
import { getUserPermissions, setHoldRequest, setPitchesModule, setSongDetails } from 'store';
import { PitchContentType } from 'store/reducers/general/types';

import { ReactComponent as ChevronDownIcon } from 'assets/arrow.svg';
import styles from './SongDetails.module.scss';

import { addPopupConfigs } from './addPopupConfigs';
import { AddPopupTypes, ISong } from 'types';

const SongDetailsActions = ({ song }: { song?: ISong }) => {
  const dispatch = useDispatch();

  const permissions = useMemoSelector(getUserPermissions);

  const onClickAction = useCallback(
    (type: ActionButtonsTypes) => {
      if (!song) return;

      switch (type) {
        case ActionButtonsTypes.holdRequest:
          dispatch(setHoldRequest({ form: { isOpen: true, workId: song.id } }));
          break;
        case ActionButtonsTypes.pitch:
          dispatch(
            setPitchesModule({
              systemPitchForm: {
                songs: song.medias.map((item) => ({
                  id: song.id,
                  mediaId: item.id,
                  path: item.path,
                  title: item.title || '',
                  version: item.version as string,
                  writers: item.writers?.map((item) => item.name || ''),
                })),
              },
              pitchContentType: PitchContentType.multipleSongPitch,
            })
          );
          break;
        case ActionButtonsTypes.addStatus:
          dispatch(
            setSongDetails({
              addPopupConfig: { ...addPopupConfigs[AddPopupTypes.status], isOpen: true, isEdit: false },
            })
          );
          break;
        case ActionButtonsTypes.addNote:
          dispatch(
            setSongDetails({
              addPopupConfig: {
                ...addPopupConfigs[AddPopupTypes.notes],
                isOpen: true,
              },
            })
          );
          break;
        default:
          break;
      }
    },
    [dispatch, song]
  );

  const actionsMenuItems = useMemo(
    () => [
      { onClick: () => onClickAction(ActionButtonsTypes.holdRequest), text: 'Request a Hold' },
      { onClick: () => onClickAction(ActionButtonsTypes.pitch), text: 'Pitch Song' },
      { onClick: () => onClickAction(ActionButtonsTypes.addStatus), text: 'Add Status' },
      { onClick: () => onClickAction(ActionButtonsTypes.addNote), text: 'Add Note' },
    ],
    [onClickAction]
  );

  return permissions.songDetailsActions ? (
    <Button
      disabled={!song}
      Icon={<ChevronDownIcon className={styles.arrowDownIcon} />}
      iconPlacement="right"
      menuItems={actionsMenuItems}
      text="Actions"
      variant={ButtonVariant.Primary}
    />
  ) : (
    <Button text="Request a Hold" onClick={() => onClickAction(ActionButtonsTypes.holdRequest)} />
  );
};

export default SongDetailsActions;

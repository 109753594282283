import { Dispatch, FC, MutableRefObject, SetStateAction } from 'react';

// Admin Controls

export interface IAdminControlsNestedData {
  id: number | string;
  title: string;
  code?: string;
}

export enum AdminControlsFilterKeys {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  status = 'status',
  team = 'defaultTeam',
  territory = 'defaultTerritory',
  role = 'role',
  isTeamAdmin = 'isTeamAdmin',
}

export type AdminControlsFilterDataType = {
  [K in AdminControlsFilterKeys]: null | string | boolean | IAdminControlsNestedData;
};

export enum StatusTabKeys {
  AllUsers = 'AllUsers',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
}

export enum HoldRequestTabKeys {
  AllStatuses = 'AllStatuses',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type StatusTabValuesType = {
  [K in StatusTabKeys]: number | null;
};

export type HoldRequestTabValuesType = {
  [K in HoldRequestTabKeys]: number | null;
};

// Hold Request

export enum HoldStatuses {
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
}

export enum HoldRequestTypes {
  user = 'user',
  team = 'team',
}

export interface IHoldRequestData {
  id: string;
  title: string;
  writers: string[];
  requesterName: string;
  teamName: string;
  status: HoldStatuses;
  songId: string;
  version: string;
  mediaId?: string;
  mediaPath?: string;
}

// Song Details

export enum SongStatusTypes {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}

export enum ErrorType {
  NotUniqueVersion = 'not-unique-version',
  Required = 'required',
}

export type ItemErrors<Values> = {
  [K in keyof Values]?: ErrorType | undefined;
};

export interface IWriterItem {
  firstName?: string;
  id?: string | number;
  lastName?: string;
  title?: string;
  name?: string;
}

export interface IMarkerBE {
  id: string;
  title: string;
  markerTime: number;
}

// moved from NewAutocomplete
export interface IAutocompleteOption {
  id: string | number;
  /**
   * Will be shown inside control
   */
  shortTitle?: string;
  title: string;
  name?: string;
}

export interface ISongMedias {
  status?: SongStatusTypes;
  draft: boolean;
  error: string | null;
  id: string;
  title: string | null;
  version: string | IAutocompleteOption | null;
  duration: string;
  waveform: string | null;
  waveForm?: string | null;
  type: string;
  path: string;
  /**
   * Percent
   */
  processing: number | null;
  uploadedOn: string | null;
  uploadedBy: string | null;
  uploadedByName: string | null;
  modifiedOn: string | null;
  modifiedBy: string | null;
  modifiedByName: string | null;
  markers: IMarkerBE[] | null;
  state?: {
    errors?: ItemErrors<ISongMedias>;
  };
  writers?: IWriterItem[];
  creators?: IWriterItem[];
  songTitle?: string;
  workCode?: string;
  fileName?: string;
  workId?: string;
}

export enum StatusColumnFields {
  id = 'id',
  artist = 'artist',
  status = 'status',
  label = 'label',
  contactInternal = 'contactInternal',
  contactExternal = 'contactExternal',
  statusDate = 'statusDate',
  actions = 'actions',
}

export type ISongStatuses = {
  [K in StatusColumnFields]: string;
};

export enum SongTagsTypes {
  artist = 'Artist',
  genre = 'Genre',
  mood = 'Mood',
  vocal = 'Vocal',
  language = 'Language',
  bpm = 'BPM',
  subGenre = 'Sub-Genre',
}

export interface IPitchContact {
  contact: string | null;
  email: string | null;
  isTeamGroup: boolean;
}

export interface ITeamPitchesData {
  id: string;
  contacts: IPitchContact[];
  pitchedBy: string;
  sentDate: string;
  label: string | null;
}

export interface ISongTag {
  isAiGenerated?: boolean;
  id?: string;
  type: SongTagsTypes;
  tag: string;
  subgenre?: string;
}

export interface ISongNote {
  id: string;
  createdByName: string;
  createdOn: string;
  createdBy: string;
  note: string;
}

export enum PlaylistColumnFields {
  title = 'title',
  mediaCount = 'mediaCount',
  createdByName = 'createdByName',
  createdOnTime = 'createdOnTime',
  team = 'team',
}

export type IPlaylistData = {
  [K in PlaylistColumnFields]: string | number;
};

export interface ISong {
  id: string;
  workCode: string;
  title: string;
  type: SongsWorkTypes;
  statuses: ISongStatuses[];
  status?: SongStatusTypes | '';
  notes: ISongNote[];
  playlists: IPlaylistData[];
  pitches: ITeamPitchesData[];
  writers: IWriterItem[];
  tags: ISongTag[];
  wcm: null | number;
  bpm: null | number;
  teamId: null | string;
  team: null | string;
  medias: ISongMedias[];
  createdBy: null | string;
  createdByName: null | string;
  creationDate: string;
  modifiedDate: string;
  modifiedBy: string;
  modifiedByName: string;
  labelView: boolean;
  featured: boolean;
}

export enum AddPopupTypes {
  status = 'Status',
  tags = 'Tags',
  notes = 'Notes',
}

export interface IAddPopupProps {
  addRef?: MutableRefObject<{ onHandleSubmit: () => void } | null>;
  setBtnDisabled: Dispatch<SetStateAction<boolean>>;
}

export interface IAddPopupConfigs {
  component: FC<IAddPopupProps>;
  type: AddPopupTypes;
}

// Songs Module

export enum SongsWorkTypes {
  preliminaryWork = 'PRELIMINARY_WORK',
  work = 'WORK',
}

// Songs Upload

export enum SongVersion {
  Acapella = 'ACAPELLA',
  Acoustic = 'ACOUSTIC',
  Demo = 'DEMO',
  FemaleVocal = 'FEMALE_VOCAL',
  GuitarVocal = 'GUITAR_VOCAL',
  Instrumental = 'INSTRUMENTAL',
  MaleVocal = 'MALE_VOCAL',
  PianoVocal = 'PIANO_VOCAL',
  Remix = 'REMIX',
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
  VocalUp = 'VOCAL_UP',
  Worktape = 'WORKTAPE',
}

export interface ISongUploadItem {
  artists?: IAutocompleteOption[];
  bpm?: number;
  error?: string;
  extension?: string;
  fileName?: string;
  genre?: IAutocompleteOption;
  groupId?: string;
  id?: string;
  language?: IAutocompleteOption[];
  mood?: IAutocompleteOption[];
  /**
   * Percent
   */
  processing?: number;
  subGenre?: IAutocompleteOption[];
  tags?: string[];
  title?: string;
  version?: SongVersion;
  waveForm?: number[];
  wcm?: string;
  writers?: IAutocompleteOption[];
  labelView?: boolean;
}

export interface ISongUploadItemExt extends ISongUploadItem {
  state?: {
    errors?: ItemErrors<ISongUploadItem>;
    saving?: boolean;
  };
}

// Playbar

export interface ICurrentTrack {
  id?: string;
  mediaId: string;
  title: string;
  path: string;
  writers: string[];
  waveForm?: number[];
  markers?: IMarkerBE[];
  songCode?: string;
  version?: string;
  status?: SongStatusTypes;
  preSignedUrl?: string;
  duration?: string;
}

export interface IListConfig {
  total: number;
  hasNextPage: boolean;
}
export enum AnalyticsEventTypes {
  registration = 'Registration',
  login = 'Login',
  copyLink = 'Get Public Share Link',
  pitchSend = 'Pitch Send',
  shareInternally = 'Share Internally Send',
  pitchSaved = 'Pitch Saved',
  downloadSong = 'Download Song',
  downloadPlayList = 'Download Playlist',
  addToFavorites = 'Add To Favorites',
  removeFromFavorites = 'Remove From Favorites',
}

import { useEffect, useRef, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import UploadPopup, { IUploadItem, UploadStatus } from 'components/Popups/UploadPopup';
import { SongDropzone } from 'components/Reusable';

import { useIsMounted, useUnMount } from 'hooks';
import Api, { IAsset } from 'services/Api';
import { setSongsModule } from 'store';
import { getGUID } from 'utils';

export interface SongDropzoneProps {
  onDrop?: () => void;
  workId?: string;
}

interface IUploadEvents {
  onChange?: (item: IUploadItem) => void;
  onUploaded?: (asset: IAsset, item: IUploadItem) => void;
}

const buildQueue = (acceptedFiles: File[], fileRejections: FileRejection[]): IUploadItem[] => {
  const mapFileToUploadItem = (file: File, error?: string): IUploadItem => ({
    errorMessage: error,
    file,
    id: getGUID(),
    progress: 0,
    status: error ? UploadStatus.Error : UploadStatus.Waiting,
    title: file.name,
  });
  return [
    ...acceptedFiles.map((file) => mapFileToUploadItem(file)),
    ...fileRejections.map(({ errors, file }) => {
      const error = errors[0]?.code === 'file-invalid-type' ? 'Invalid File Type' : errors[0]?.message;
      return mapFileToUploadItem(file, error);
    }),
  ];
};

const getNextWaitingItem = (items: IUploadItem[]): IUploadItem | undefined => {
  return items.find((i) => i.status === UploadStatus.Waiting);
};

const pushToUploadQueue = (queue: IUploadItem[], addQueue: IUploadItem[]): IUploadItem[] => {
  addQueue.forEach((item) => {
    if (!queue.find((queueItem) => queueItem.id === item.id)) queue.push(item);
  });
  return queue;
};

const useUploadQueue = (items: IUploadItem[], workId?: string, events?: IUploadEvents) => {
  const completed = useRef(true);
  const isMounted = useIsMounted();
  const queue = useRef<IUploadItem[]>([]);

  useEffect(() => {
    pushToUploadQueue(queue.current, items);
    if (!completed.current) return; // Check if there is no uploading process started
    (async () => {
      completed.current = false; // Start the process
      let item = getNextWaitingItem(queue.current);
      while (item && isMounted()) {
        item.status = UploadStatus.InProgress;
        try {
          const asset = await Api.uploadAsset({
            workId: workId,
            originalFilename: item.file.name,
            contentType: item.file.type,
          });

          item.uploadId = asset.id;

          if (isMounted() && events) {
            events.onChange?.(item);
          }

          if (!asset.fileName) throw new Error('Asset should have a fileName');

          await Api.uploadAssetToBucket({ file: item.file, uuidFileName: asset.fileName }, (progress, xhr) => {
            if (item) {
              item.progress = progress;
              if (item.status === UploadStatus.Canceled) xhr.abort();
              if (isMounted() && events?.onChange) events.onChange(item);
            }
          });

          item.status = UploadStatus.Uploaded;
          if (isMounted() && events) {
            events.onUploaded?.(asset, item);
          }
        } catch (e) {
          if ((item.status as UploadStatus) !== UploadStatus.Canceled) {
            item.errorMessage = 'Error';
            item.status = UploadStatus.Error;
          }
          isMounted() && events?.onChange && events.onChange(item);
        }
        item = getNextWaitingItem(queue.current);
      }
      queue.current = [];
      completed.current = true; // Complete the process
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
};

const SongsDropzone = ({ onDrop, workId }: SongDropzoneProps) => {
  const [items, setItems] = useState<IUploadItem[]>([]);
  const [uploadedAssets, setUploadedAssets] = useState<IAsset[]>([]);

  const dispatch = useDispatch();

  const uploadEvents: IUploadEvents = {
    onChange: (item) => setItems((q) => q.map((i) => (i.id === item.id ? item : i))),
    onUploaded: (asset, item) => {
      setItems((q) => q.map((i) => (i.id === item.id ? item : i)));
      setUploadedAssets((prevUploadedAssets) => [asset, ...prevUploadedAssets]);
    },
  };
  useUploadQueue(items, workId, uploadEvents);

  useUnMount(() => {
    dispatch(setSongsModule({ localAssets: [] }));
  });

  return (
    <>
      <SongDropzone
        onDrop={(a, r) => {
          setItems((prevQueue) => [...prevQueue, ...buildQueue(a, r)]);
          onDrop && onDrop();
        }}
      />
      {items.length > 0 && (
        <UploadPopup
          items={items}
          onClose={() => setItems([])}
          onComplete={() => {
            dispatch(setSongsModule({ localAssets: uploadedAssets }));
            setUploadedAssets([]);
          }}
          onItemCancel={(item, status) => {
            item.status = status;
            setItems((q) => q.slice());
          }}
        />
      )}
    </>
  );
};

export default SongsDropzone;

import React from 'react';
import { GridApi, RowNode } from 'ag-grid-community';

import { PlayButton, Tags } from 'components/Reusable';
import { ColumnFields, ColumnHeaderNames } from 'components/Songs/data';
import styles from 'components/SongsUpload/SongsUpload.module.scss';
import { SwitchBtn } from 'components/UI';
import { DataGridColDef } from 'components/UI/DataGrid';

import { getDataGridItems, SONG_TITLE_MAX_LENGTH } from 'utils';

import { ReactComponent as CloseIcon } from 'assets/close-2.svg';

import ActionsCell from './ActionsCell';
import ColumnHeader from './ColumnHeader';
import InputCell from './InputCell';
import SelectCell, { ISelectOption } from './SelectCell';
import { IAutocompleteOption, ICurrentTrack, ISongUploadItem, ISongUploadItemExt, SongVersion } from 'types';

const reduceToArrayString = (
  arr: (string | number | IAutocompleteOption | IAutocompleteOption[] | undefined | null)[]
): string[] => {
  return arr.reduce((result: string[], value) => {
    if (typeof value === 'undefined' || value === null) return result;
    if (typeof value === 'string') {
      if (value) result.push(value);
      return result;
    }
    if (typeof value === 'number') {
      result.push(value.toString());
      return result;
    }
    if (Array.isArray(value)) {
      value.forEach((v) => result.push(v.title));
      return result;
    }
    result.push(value.title);
    return result;
  }, []);
};

export const getRelatedGroupNodes = (node: RowNode, searchArea: GridApi | RowNode[]): RowNode[] => {
  const groupId = (node.data as ISongUploadItem).groupId;
  if (!groupId) return [];

  if (Array.isArray(searchArea)) {
    return searchArea.filter((n) => (n.data as ISongUploadItem).groupId === groupId && n !== node);
  }
  const connectedNodes: RowNode[] = [];
  searchArea.getModel().forEachNode((n) => {
    if ((n.data as ISongUploadItem).groupId === groupId && n !== node) {
      connectedNodes.push(n);
    }
  });
  return connectedNodes;
};

export const getTags = (item: ISongUploadItem | undefined, type: 'tags' | 'writers'): string[] => {
  return type === 'writers'
    ? reduceToArrayString([item?.writers])
    : reduceToArrayString([
        item?.genre,
        item?.subGenre,
        item?.artists,
        item?.mood,
        item?.language,
        typeof item?.bpm === 'number' ? item.bpm : undefined,
      ]);
};

const mapGridToTracks = (gridApi: GridApi): ICurrentTrack[] =>
  getDataGridItems<ISongUploadItem>(gridApi).map((i) => ({
    id: '',
    mediaId: i.id || '',
    path: i.fileName || '',
    title: i.title || '',
    waveForm: i.waveForm,
    writers: [],
  }));

export interface IEvents {
  onAddToPW: (nodes: RowNode[], e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancelEdit: () => void;
  onDelete: (nodes: RowNode[]) => void;
  onEditTags: (nodes: RowNode[], mode: 'single' | 'multiple') => void;
  onEditWriters: (nodes: RowNode[], mode: 'single' | 'multiple') => void;
  onEditVersions: (nodes: RowNode[], mode: 'single' | 'multiple') => void;
  onGroup: (nodes: RowNode[]) => void;
  onSave: (nodes: RowNode[]) => void | Promise<void>;
  onSaveTags: (nodes: RowNode[]) => void;
  onSaveWriters: (nodes: RowNode[]) => void;
  onSaveVersions: (nodes: RowNode[]) => void;
  onUngroup: (nodes: RowNode[]) => void;
}

export const getColumns = ({
  onDelete,
  onEditTags,
  onEditWriters,
  onSave,
  onUngroup,
}: IEvents): DataGridColDef<ISongUploadItem>[] => [
  {
    cellClass: 'defaultPadding',
    headerClass: 'defaultPadding',
    cellClassRules: {
      [styles.disabledCheckbox]: ({ data: { groupId, id } }: { data: ISongUploadItem }) =>
        Boolean(groupId && groupId !== id),
    },
    checkboxSelection: true,
    field: '',
    headerCheckboxSelection: true,
    maxWidth: 50,
  },
  {
    cellClass: 'defaultPadding',
    headerClass: 'defaultPadding',
    cellRendererFramework: ({ api, data, node }) => {
      const onUngroupClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onUngroup([node, ...getRelatedGroupNodes(node, api)]);
      };
      return data?.groupId === data?.id ? (
        <div className={styles.groupTag}>
          Group
          <button onClick={onUngroupClick}>
            <CloseIcon height="9" width="9" />
          </button>
        </div>
      ) : (
        <PlayButton
          disabled={Boolean(data?.error)}
          extension={data?.extension}
          getTracks={() => mapGridToTracks(api)}
          mediaId={data?.id}
          processing={data?.processing}
        />
      );
    },
    field: 'processing',
    headerName: '',
    maxWidth: 50,
  },
  {
    field: ColumnFields.songTitle,
    minWidth: 160,
    flex: 1,
    headerComponentFramework: () => <ColumnHeader required>{ColumnHeaderNames.songTitle}</ColumnHeader>,
    cellRendererFramework: ({ api, data, node }) => (
      <InputCell
        colKey={ColumnFields.songTitle}
        disabled={Boolean(
          data?.error || typeof data?.processing !== 'undefined' || (data?.groupId && data.groupId !== data.id)
        )}
        error={Boolean(data?.error)}
        getRelatedNodes={() => getRelatedGroupNodes(node, api)}
        iconPlaceWidth={40}
        iconRender={data?.extension ? `.${data.extension}` : undefined}
        node={node}
        placeholder="Enter song title"
        required
        value={data?.title}
        maxLength={SONG_TITLE_MAX_LENGTH}
      />
    ),
  },
  {
    field: ColumnFields.version,
    headerComponentFramework: () => <ColumnHeader required>{ColumnHeaderNames.version}</ColumnHeader>,
    maxWidth: 120,
    minWidth: 120,
    cellRendererFramework: ({ data, node }) => (
      <>
        {data?.error && <div className={styles.errorMessage}>{data.error}</div>}
        {typeof data?.processing === 'number' && <div>Processing...</div>}
        {!data?.error && typeof data?.processing !== 'number' && data?.groupId !== data?.id && (
          <SelectCell
            colKey={ColumnFields.version}
            disabled={typeof data?.processing !== 'undefined'}
            node={node}
            options={getVersionOptions()}
            required
            value={data?.version}
          />
        )}
      </>
    ),
  },
  {
    field: ColumnFields.writers,
    maxWidth: 150,
    width: 150,
    headerComponentFramework: () => <ColumnHeader required>{ColumnHeaderNames.writers}</ColumnHeader>,
    cellRendererFramework: ({ api, node }) => {
      const data = node.data as ISongUploadItemExt;
      if (data?.error || typeof data?.processing === 'number' || (data?.groupId && data.groupId !== data.id)) return '';
      return (
        <Tags
          error={Boolean(data.state?.errors?.writers)}
          onEdit={() => {
            api.deselectAll();
            node.setSelected(true);
            onEditWriters([node], 'single');
          }}
          tags={getTags(data, 'writers')}
        />
      );
    },
  },
  {
    field: ColumnFields.labelView,
    maxWidth: 100,
    width: 100,
    headerComponentFramework: () => (
      <ColumnHeader
        required
        tooltip="Label View means this demo can be viewed by all WMG A&Rs and shared with their Recorded Music artists."
      >
        {ColumnHeaderNames.labelView}
      </ColumnHeader>
    ),
    cellRendererFramework: ({ node }) => {
      const data = node.data as ISongUploadItemExt;
      if (data?.error || typeof data?.processing === 'number' || (data?.groupId && data.groupId !== data.id)) return '';
      return (
        <SwitchBtn
          checked={data?.labelView}
          onChangeSwitch={(checked) => node.setDataValue(ColumnFields.labelView, checked)}
        />
      );
    },
  },
  {
    field: ColumnFields.wcm,
    headerComponentFramework: () => <ColumnHeader>{ColumnHeaderNames.wcm}</ColumnHeader>,
    maxWidth: 100,
    minWidth: 100,
    cellRendererFramework: ({ api, data, node }) =>
      data?.error || typeof data?.processing === 'number' ? (
        ''
      ) : (
        <InputCell
          colKey={ColumnFields.wcm}
          disabled={Boolean(data?.groupId && data.groupId !== data.id)}
          getRelatedNodes={() => getRelatedGroupNodes(node, api)}
          iconRender="%"
          max={100}
          min={0}
          node={node}
          type="number"
          value={data?.wcm}
        />
      ),
  },
  {
    field: ColumnFields.tags,
    maxWidth: 150,
    width: 150,
    headerComponentFramework: () => <ColumnHeader>{ColumnHeaderNames.tags}</ColumnHeader>,
    cellRendererFramework: ({ api, data, node }) =>
      data?.error || typeof data?.processing === 'number' || (data?.groupId && data.groupId !== data.id) ? (
        ''
      ) : (
        <Tags
          onEdit={() => {
            api.deselectAll();
            node.setSelected(true);
            onEditTags([node], 'single');
          }}
          tags={data?.tags}
        />
      ),
  },
  {
    field: 'state',
    headerName: 'Actions',
    cellRendererFramework: ({ api, data, node }) => (
      <ActionsCell
        node={node}
        onDelete={() => (data?.groupId ? onDelete([node, ...getRelatedGroupNodes(node, api)]) : onDelete([node]))}
        onSave={() => (data?.groupId ? onSave([node, ...getRelatedGroupNodes(node, api)]) : onSave([node]))}
      />
    ),
    headerClass: styles.actionsHeaderCell,
    minWidth: 94,
    maxWidth: 94,
  },
];

export const getVersionOptions = (): ISelectOption[] => [
  { id: SongVersion.Acapella, shortTitle: 'Acap', title: 'Acapella' },
  { id: SongVersion.Acoustic, shortTitle: 'Acou', title: 'Acoustic' },
  { id: SongVersion.Demo, title: 'Demo' },
  { id: SongVersion.FemaleVocal, shortTitle: 'F Vox', title: 'Female Vocal' },
  { id: SongVersion.GuitarVocal, shortTitle: 'GV', title: 'Guitar Vocal' },
  { id: SongVersion.Instrumental, shortTitle: 'Inst', title: 'Instrumental' },
  { id: SongVersion.MaleVocal, shortTitle: 'M Vox', title: 'Male Vocal' },
  { id: SongVersion.PianoVocal, shortTitle: 'PV', title: 'Piano Vocal' },
  { id: SongVersion.Remix, title: 'Remix' },
  { id: SongVersion.V1, title: 'V1' },
  { id: SongVersion.V2, title: 'V2' },
  { id: SongVersion.V3, title: 'V3' },
  { id: SongVersion.VocalUp, shortTitle: 'Vox Up', title: 'Vocal Up' },
  { id: SongVersion.Worktape, shortTitle: 'WT', title: 'Worktape' },
];

import { Button, PlayButton } from 'components/Reusable';
import { ButtonVariant } from 'components/Reusable/Button';
import { Tooltip } from 'components/UI';

import { getSongVersion } from 'utils';

import { ReactComponent as TrashIcon } from 'assets/trash-2.svg';
import styles from './SystemPitchForm.module.scss';

export interface ISong {
  id: string;
  mediaId: string | null;
  path: string | null;
  title: string;
  version?: string | null;
  writers?: string[];
}

interface ISongRow {
  onDelete?: (song: ISong) => void;
  song: ISong;
  songs: ISong[];
}

const SongRow = ({ onDelete, song, songs }: ISongRow) => {
  const writers = song.writers?.join(' / ');
  return (
    <div className={styles.row}>
      <div className={styles.playCell}>
        <PlayButton
          extension={song.path}
          getTracks={() =>
            songs.map((item) => ({
              mediaId: item.mediaId || '',
              id: item.id,
              title: item.title || '',
              writers: item.writers || [],
              path: item.path || '',
            }))
          }
          mediaId={song.mediaId}
        />
      </div>
      <div className={styles.titleCell}>
        <div>
          <Tooltip text={song.title}>
            <div className={styles.title}>{song.title}</div>
          </Tooltip>
        </div>
        {writers && (
          <div>
            <Tooltip text={writers}>
              <div className={styles.description}>{writers}</div>
            </Tooltip>
          </div>
        )}
      </div>
      <div className={styles.versionCell}>{getSongVersion(song.version)}</div>
      <div className={styles.actionsCell}>
        {onDelete && (
          <Button onClick={() => onDelete(song)} variant={ButtonVariant.Text}>
            <TrashIcon height={16} width={16} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default SongRow;
